import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "./NavMenu";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import SubscriptionProgress from "./SubscriptionProgress";
import PlanSummary from "./PlanSummary";

const SubscriptionCheckout = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(2); // Account creation is step 1
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState(null);
    const promiseKey = process.env.REACT_APP_STRIPE_PROMISE_KEY;
    const stripePromise = loadStripe(promiseKey);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login', {
                state: {
                    redirectTo: '/subscription-checkout',
                    tier: state?.tier
                }
            });
            return;
        }

        if (!state?.tier) {
            navigate('/subscriptions');
            return;
        }
    }, []);

    // Add handleSubscribe function
    const handleSubscribe = async (tier) => {
        const token = localStorage.getItem("token");
        const stripe = await stripePromise;
        const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`,
            {
                tier: tier,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            },
        );

        const sessionId = response.data;

        // If it's a free subscription, handle success directly
        if (sessionId.startsWith("free_subscription_")) {
            window.location.href = `/success`;
            return;
        }

        // For paid subscriptions, redirect to Stripe Checkout
        const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
        });

        if (result.error) {
            throw new Error(result.error.message);
        }
    };

    const handleSubscriptionConfirm = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setError('Please log in to continue');
            return;
        }

        setIsProcessing(true);
        setError(null);

        try {
            setCurrentStep(3);
            await handleSubscribe(state.tier);
        } catch (err) {
            setError('There was a problem processing your subscription. Please try again.');
        } finally {
            setIsProcessing(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <SubscriptionProgress currentStep={currentStep} />

                    <div className="max-w-2xl mx-auto mt-8">
                        <h1 className="text-3xl font-bold text-gray-900 text-center mb-8">
                            Confirm Your Subscription
                        </h1>

                        {error && (
                            <div className="mb-6 p-4 rounded-lg bg-red-50 text-red-600">
                                {error}
                            </div>
                        )}

                        <PlanSummary
                            tier={state.tier}
                            onChangePlan={() => navigate('/subscriptions')}
                        />

                        <div className="mt-8 space-y-4">
                            {state.tier !== 'Free' && (
                                <div className="bg-blue-50 rounded-lg p-4 text-sm text-blue-700">
                                    Your payment method will be securely collected in the next step.
                                </div>
                            )}

                            <button
                                onClick={handleSubscriptionConfirm}
                                disabled={isProcessing}
                                className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors disabled:opacity-50"
                            >
                                {isProcessing ? (
                                    <div className="flex items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </div>
                                ) : (
                                    'Confirm Subscription'
                                )}
                            </button>

                            <button
                                onClick={() => navigate('/subscriptions')}
                                className="w-full py-3 px-6 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50 font-medium transition-colors"
                            >
                                Cancel
                            </button>
                        </div>

                        <div className="mt-6 text-center text-sm text-gray-500">
                            By confirming your subscription, you agree to our{' '}
                            <a href="/terms" className="text-blue-600 hover:text-blue-700">
                                Terms of Service
                            </a>{' '}
                            and{' '}
                            <a href="/privacy" className="text-blue-600 hover:text-blue-700">
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SubscriptionCheckout;
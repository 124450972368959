import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import Subscriptions from './components/Subscriptions';
import Success from './components/Success';
import Cancel from './components/Cancel';
import EmailConfirmed from './components/EmailConfirmed';
import RegistrationSuccess from './components/RegistrationSuccess';
import SubscriptionCheckout from './components/SubscriptionCheckout';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/success" element={<Success />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/email-confirmed" element={<EmailConfirmed />} />
        <Route path="/registration-success" element={<RegistrationSuccess />} />
        <Route path="/subscription-checkout" element={<SubscriptionCheckout />} />
      </Routes>
    </div>
  );
}

export default App;

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CreditCard, Mail, Lock, AlertCircle } from 'lucide-react';
import Navbar from './NavMenu';

// Since you don't have the shadcn/ui components set up, let's create a simple Alert component
const Alert = ({ children, className }) => (
    <div className={`p-4 rounded-lg bg-white shadow-lg border border-gray-200 ${className}`}>
        {children}
    </div>
);

const AlertTitle = ({ children }) => (
    <h4 className="text-lg font-semibold mb-1">{children}</h4>
);

const AlertDescription = ({ children }) => (
    <p className="text-gray-600">{children}</p>
);

const Profile = () => {
    const [profile, setProfile] = useState({ email: '' });
    const [newEmail, setNewEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [subscription, setSubscription] = useState(null);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const navigate = useNavigate();

    const fetchProfileAndSubscription = useCallback(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        try {
            const profileResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setProfile(profileResponse.data);
            setNewEmail(profileResponse.data.email);

            const subscriptionResponse = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setSubscription(subscriptionResponse.data);
        } catch (error) {
            setMessage('Error fetching profile or subscription data.');
        }
    }, [navigate]);

    useEffect(() => {
        fetchProfileAndSubscription();
    }, [fetchProfileAndSubscription]);

    const handleEmailChange = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile/update`, { email: newEmail }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Email updated successfully!');
        } catch (error) {
            setMessage('Error updating email.');
        }
    };

    const handleChangePassword = async () => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/profile/change-password`, { currentPassword, newPassword }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage('Password changed successfully!');
        } catch (error) {
            setMessage('Error changing password.');
        }
    };

    const handleCancelSubscription = async (cancelType) => {
        const token = localStorage.getItem('token');
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/subscription/cancel`, { cancelType }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage(`Subscription ${cancelType === 'immediate' ? 'cancelled immediately' : 'will be cancelled at the end of the billing period'}!`);
            fetchProfileAndSubscription();
        } catch (error) {
            setMessage('Error cancelling subscription.');
        } finally {
            setShowCancelModal(false);
        }
    };

    const handleManageSubscription = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/subscription/create-portal-session`,
                {},
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            window.location.href = response.data;
        } catch (error) {
            setMessage('Error accessing subscription management.');
        }
    };

    const isSubscriptionActive = subscription && (subscription.status === "Free" || subscription.status === "Basic" || subscription.status === "Pro");

    return (
        <>
            < Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
            <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                {/* Header Section */}
                <div className="text-center max-w-3xl mx-auto mb-12">
                    <h1 className="text-4xl font-bold text-gray-900 mb-4">
                        Account Settings
                    </h1>
                    <p className="text-xl text-gray-600">
                        Manage your profile and subscription preferences
                    </p>
                </div>

                {/* Main Content Grid */}
                <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
                    {/* Profile Section */}
                    <div className="md:col-span-2 space-y-6">
                        {/* Email Update Section */}
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                            <div className="flex items-center mb-6">
                                <Mail className="h-6 w-6 text-blue-600 mr-3" />
                                <h2 className="text-2xl font-bold text-gray-900">Email Address</h2>
                            </div>
                            <div className="space-y-4">
                                <input
                                    type="email"
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="Enter new email"
                                />
                                <button
                                    onClick={handleEmailChange}
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Update Email
                                </button>
                            </div>
                        </div>

                        {/* Password Update Section */}
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                            <div className="flex items-center mb-6">
                                <Lock className="h-6 w-6 text-blue-600 mr-3" />
                                <h2 className="text-2xl font-bold text-gray-900">Password</h2>
                            </div>
                            <div className="space-y-4">
                                <input
                                    type="password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="Current password"
                                />
                                <input
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                    placeholder="New password"
                                />
                                <button
                                    onClick={handleChangePassword}
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* Subscription Status Section */}
                    <div className="md:col-span-1">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8 sticky top-8">
                            <div className="flex items-center mb-6">
                                <CreditCard className="h-6 w-6 text-blue-600 mr-3" />
                                <h2 className="text-2xl font-bold text-gray-900">Subscription</h2>
                            </div>
                            
                            {subscription ? (
                                <div className="space-y-4">
                                    <div className="p-4 bg-gray-50 rounded-lg">
                                        <p className="text-gray-600">Current Plan</p>
                                        <p className="text-2xl font-bold text-gray-900">{subscription.status}</p>
                                    </div>

                                    {subscription.expiresIn && (
                                        <div className="flex items-center text-gray-600">
                                            <AlertCircle className="h-5 w-5 mr-2" />
                                            Expires in {subscription.expiresIn} days
                                        </div>
                                    )}

                                    <div className="space-y-3">
                                        <button
                                            onClick={handleManageSubscription}
                                            disabled={!subscription?.stripeCustomerId}
                                            className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            Manage Subscription
                                        </button>
                                        {isSubscriptionActive && (
                                            <button
                                                onClick={() => setShowCancelModal(true)}
                                                className="w-full py-3 px-6 rounded-lg border border-red-500 text-red-500 hover:bg-red-50 font-medium transition-colors"
                                            >
                                                Cancel Subscription
                                            </button>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className="space-y-4">
                                    <p className="text-gray-600">No active subscription</p>
                                    <button
                                        onClick={() => navigate('/subscriptions')}
                                        className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                    >
                                        Choose a Plan
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Message Toast */}
                {message && (
                    <div className="fixed bottom-4 right-4 z-50">
                        <Alert className="max-w-md">
                            <AlertTitle>Notification</AlertTitle>
                            <AlertDescription>{message}</AlertDescription>
                        </Alert>
                    </div>
                )}

                {/* Cancel Modal */}
                {showCancelModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-2xl p-8 max-w-md w-full mx-4">
                            <h3 className="text-2xl font-bold text-gray-900 mb-4">Cancel Subscription</h3>
                            <p className="text-gray-600 mb-6">Are you sure you want to cancel your subscription? Choose how you'd like to proceed:</p>
                            
                            <div className="space-y-3">
                                <button
                                    onClick={() => setShowCancelModal(false)}
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Keep Subscription
                                </button>
                                <button
                                    onClick={() => handleCancelSubscription('end_of_cycle')}
                                    className="w-full py-3 px-6 rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-900 font-medium transition-colors"
                                >
                                    Cancel at Period End
                                </button>
                                <button
                                    onClick={() => handleCancelSubscription('immediate')}
                                    className="w-full py-3 px-6 rounded-lg border border-red-500 text-red-500 hover:bg-red-50 font-medium transition-colors"
                                >
                                    Cancel Immediately
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};

export default Profile;
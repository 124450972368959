import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './NavMenu';
import { Mail } from 'lucide-react';

const RegistrationSuccess = () => {
    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8 text-center">
                            {/* Email Icon */}
                            <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                                <Mail className="h-8 w-8 text-blue-600" />
                            </div>

                            <h1 className="text-2xl font-bold text-gray-900 mb-4">
                                Verify Your Email Address
                            </h1>

                            <div className="space-y-4 text-gray-600">
                                <p>
                                    Thanks for signing up! We've sent a verification link to your email address.
                                </p>
                                <p>
                                    Please check your inbox and click the verification link to activate your account.
                                </p>
                                <p className="text-sm">
                                    If you don't see the email, check your spam folder.
                                </p>
                            </div>

                            <div className="mt-8 space-y-4">
                                <Link
                                    to="/login"
                                    className="inline-block w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Go to Login
                                </Link>
                                <p className="text-sm text-gray-500">
                                    Need help? <a href="mailto:support@yourcompany.com" className="text-blue-600 hover:text-blue-700">Contact Support</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegistrationSuccess;
import React from 'react';
import { Bell } from 'lucide-react';

const SubscriptionCard = ({ status, expiresIn, maxPreferences, notificationDelay, preferencesCount }) => {
    const getStatusColor = (status) => {
      switch (status.toLowerCase()) {
        case 'premium': return 'bg-purple-100 text-purple-800';
        case 'pro': return 'bg-blue-100 text-blue-800';
        default: return 'bg-gray-100 text-gray-800';
      }
    };

    return (
      <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <Bell className="h-5 w-5 text-blue-600" />
            <h3 className="font-semibold text-gray-900">Subscription Status</h3>
          </div>
          <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(status)}`}>
            {status}
          </span>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div className="p-4 bg-gray-50 rounded-lg">
            <p className="text-sm text-gray-600">Time Until Expiry</p>
            <p className="text-lg font-semibold text-gray-900">{expiresIn} days</p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <p className="text-sm text-gray-600">Alert Frequency</p>
            <p className="text-lg font-semibold text-gray-900">
              {notificationDelay === 1440 ? 'Daily' : `${notificationDelay}min`}
            </p>
          </div>
          <div className="p-4 bg-gray-50 rounded-lg">
            <p className="text-sm text-gray-600">Preferences Usage</p>
            <p className="text-lg font-semibold text-gray-900">{preferencesCount}/{maxPreferences}</p>
          </div>
        </div>

        <div className="w-full bg-gray-200 rounded-full h-2">
          <div 
            className="bg-blue-600 h-2 rounded-full transition-all"
            style={{ width: `${(preferencesCount / maxPreferences) * 100}%` }}
          />
        </div>
      </div>
    );
  };

export default SubscriptionCard;

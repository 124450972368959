// Success.js
import React, { useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import Navbar from './NavMenu';
import { CheckCircle, ArrowRight } from 'lucide-react';

const Success = () => {
    const [searchParams] = useSearchParams();
    const sessionId = searchParams.get('session_id');

    useEffect(() => {
        if (sessionId) {
            console.log("Payment was successful, session ID:", sessionId);
        }
    }, [sessionId]);

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto">
                        <div className="rounded-2xl border border-green-200 bg-white shadow-sm p-8 text-center">
                            {/* Success Icon */}
                            <div className="mx-auto w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-6">
                                <CheckCircle className="h-8 w-8 text-green-600" />
                            </div>

                            <h1 className="text-2xl font-bold text-gray-900 mb-4">
                                Welcome to Your New Plan!
                            </h1>

                            <div className="space-y-4 text-gray-600 mb-8">
                                <p>
                                    Your subscription has been successfully activated. You now have access to all the features of your new plan.
                                </p>
                                <p className="text-sm">
                                    Transaction ID: {sessionId && <span className="font-mono text-gray-500">{sessionId.slice(-8)}</span>}
                                </p>
                            </div>

                            <div className="space-y-4">
                                <Link
                                    to="/dashboard"
                                    className="inline-flex items-center justify-center w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Go to Dashboard
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </Link>

                                <div className="text-sm text-gray-500">
                                    Need help? <a href="mailto:support@dwellio.co.uk" className="text-blue-600 hover:text-blue-700">Contact Support</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Success;
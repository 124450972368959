import React, { useState, useEffect, useCallback } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import axios from "axios";
import _ from "lodash";

const AddOrEditPreferenceModal = ({
    showModal,
    handleCloseModal,
    isEditing,
    errorMessage,
    newPreference,
    handleChange,
    handleAddPreference,
    handleUpdatePreference,
    dropdownOptions,
    isAdding,
}) => {
    const [citySuggestions, setCitySuggestions] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);
    const [isCityValid, setIsCityValid] = useState(false);
    const [showWarning, setShowWarning] = useState(false); // State to control warning popup
    var googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY

    // Debounced fetch city suggestions function
    const fetchCitySuggestions = useCallback(
        _.debounce(async (query) => {
            if (!query) return; // Avoid making requests for empty input
            setLoadingCities(true);
            try {
                const response = await axios.get(
                    `https://maps.googleapis.com/maps/api/place/autocomplete/json`, {
                        params: {
                            input: query,
                            key: googleApiKey, // Replace with your API key
                            components: "country:GB", // Limit results to the UK
                            types: "(cities)", // Limit results to cities only
                        },
                    }
                );
                console.log("City suggestions:", response.data.predictions);
                const cities = response.data.predictions.map((item) => {
                    // Clean city names by removing ", UK"
                    const cityName = item.description.split(',')[0].trim();
                    return cityName; // Return cleaned city name
                });
                setCitySuggestions(cities);
            } catch (error) {
                console.error("Error fetching city suggestions:", error);
            }
            setLoadingCities(false);
        }, 500), // Debounce delay of 500ms
        []
    );

    // Handle city input change
    const handleCityChange = (e) => {
        const { value } = e.target;
        handleChange(e); // Call the parent handleChange to manage the state
        setIsCityValid(false); // Reset valid city state when input changes
        fetchCitySuggestions(value); // Debounced API call
    };

    // Handle city suggestion selection
    const handleCitySelection = (city) => {
        handleChange({
            target: { name: "city", value: city },
        });
        setCitySuggestions([]); // Clear suggestions after selection
        setIsCityValid(true); // Set city as valid when selected from the dropdown
    };

    // Validate if user input matches any suggestion
    useEffect(() => {
        if (citySuggestions.includes(newPreference.city)) {
            setIsCityValid(true); // If input matches a suggestion, mark it valid
        }
    }, [newPreference.city, citySuggestions]);

    // Handle the Update Preference action
    const handleUpdateClick = () => {
        setShowWarning(true); // Show warning modal
    };

    // Handle the confirmation of the update action
    const confirmUpdate = () => {
        handleUpdatePreference(); // Call the parent function to update preference
        setShowWarning(false); // Close the warning modal
    };

    return (
        <>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? "Edit Preference" : "Add New Preference"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                    <Form>
                        <Form.Group controlId="formCity">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                type="text"
                                name="city"
                                value={newPreference.city || ""}
                                onChange={handleCityChange}
                                placeholder="City"
                            />
                        </Form.Group>

                        <Form.Group controlId="formPriceRangeMin">
                            <Form.Label>Min Price</Form.Label>
                            <Form.Control
                                as="select"
                                name="priceRangeMin"
                                value={newPreference.priceRangeMin || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Min Price</option>
                                {dropdownOptions.PriceRange.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formPriceRangeMax">
                            <Form.Label>Max Price</Form.Label>
                            <Form.Control
                                as="select"
                                name="priceRangeMax"
                                value={newPreference.priceRangeMax || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Max Price</option>
                                {dropdownOptions.PriceRange.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formPropertyType">
                            <Form.Label>Property Type</Form.Label>
                            <Form.Control
                                as="select"
                                name="propertyType"
                                value={newPreference.propertyType || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Property Type</option>
                                {dropdownOptions.PropertyType.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formDistanceRadius">
                            <Form.Label>Distance Radius</Form.Label>
                            <Form.Control
                                as="select"
                                name="distanceRadius"
                                value={newPreference.distanceRadius || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Distance Radius</option>
                                {dropdownOptions.DistanceRadius.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMinBedrooms">
                            <Form.Label>Min Bedrooms</Form.Label>
                            <Form.Control
                                as="select"
                                name="minBedrooms"
                                value={newPreference.minBedrooms === 0 ? "Studio" : newPreference.minBedrooms || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Min Bedrooms</option>
                                {dropdownOptions.Bedrooms.map((option, index) => (
                                    <option key={index} value={option === 0 ? "Studio" : option.toString()}>
                                        {option === 0 ? "Studio" : option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formMaxBedrooms">
                            <Form.Label>Max Bedrooms</Form.Label>
                            <Form.Control
                                as="select"
                                name="maxBedrooms"
                                value={newPreference.maxBedrooms || ""}
                                onChange={handleChange}
                            >
                                <option value="">Please select Max Bedrooms</option>
                                {dropdownOptions.Bedrooms.map((option, index) => (
                                    <option key={index} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={isEditing ? handleUpdateClick : handleAddPreference} // Call the appropriate function based on the state
                    >
                        {isEditing ? "Update Preference" : "Add Preference"}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Warning Modal */}
            <Modal show={showWarning} centered onHide={() => setShowWarning(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Preference Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Updating your preferences will remove all existing matching listings associated with this preference.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWarning(false)}>
                        No
                    </Button>
                    <Button variant="danger" onClick={confirmUpdate}>
                        Yes, Update
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddOrEditPreferenceModal;

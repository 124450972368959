import React, { useState } from 'react';
import {
  AlertCircle,
  ArrowUpRight
} from 'lucide-react';
import CustomPagination from './CustomPagination';

const MatchedListingsTable = ({ listings = [], userTier, preferences = [] }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const listingsPerPage = 20;

  const effectiveTier = userTier;
  const totalMatchedCount = preferences?.reduce((acc, pref) => acc + (pref.listingsMatched || 0), 0) || 0;

  const getVisibleListings = () => {
    if (effectiveTier === 'Free') {
      return listings.slice(0, 5);
    }
    if (effectiveTier === 'Basic') {
      return listings.slice(0, 20);
    }
    return listings;
  };

  const visibleListings = getVisibleListings();
  const hasMoreListings = totalMatchedCount > visibleListings.length;

  return (
      <div className="bg-white rounded-lg shadow-sm p-6 relative space-y-4">
        <div className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">Matched Properties</h2>
          <p className="text-gray-600 mt-1">Properties matching your search criteria</p>
        </div>

        {/* Basic tier upgrade notification - shown above table */}
        {effectiveTier === 'Basic' && hasMoreListings && (
            <div className="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-4">
              <div className="flex items-start">
                <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5" />
                <div className="ml-3">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {totalMatchedCount - visibleListings.length} More Properties Available
                  </h3>
                  <p className="text-gray-600 mt-1 mb-4">
                    Upgrade to Pro to unlock all matched properties and get instant notifications.
                  </p>
                  <button
                      onClick={() => window.location.href = '/subscription'}
                      className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    Upgrade to Pro
                  </button>
                </div>
              </div>
            </div>
        )}

        <div>
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Added</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notification</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {/* Visible listings */}
            {visibleListings.map((listing) => (
                <tr key={listing.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.location}</td>
                  <td className="px-6 py-4 whitespace-nowrap">£{listing.price.toLocaleString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.propertyType}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{listing.bedrooms}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{new Date(listing.dateAdded).toLocaleDateString()}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {listing.dateNotificationSent
                        ? new Date(listing.dateNotificationSent).toLocaleDateString()
                        : 'Not Sent'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <a
                        href={listing.listingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:text-blue-800"
                    >
                      View Listing
                    </a>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>

          {/* Free tier upgrade message - shown below visible listings */}
          {effectiveTier === 'Free' && hasMoreListings && (
              <div className="relative mt-8 bg-white/90 p-8 border-t border-gray-200">
                <div className="text-center max-w-lg mx-auto">
                  <AlertCircle className="h-12 w-12 text-blue-500 mx-auto mb-4"/>
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {totalMatchedCount - visibleListings.length} More Properties Found!
                  </h3>
                  <p className="text-gray-600 mb-6">
                    Unlock all properties by upgrading to Pro
                  </p>
                  <ul className="text-left space-y-2 mb-6 max-w-xs mx-auto">
                    <li className="flex items-center gap-2 text-gray-700">
                      <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                      <span>See all matched properties</span>
                    </li>
                    <li className="flex items-center gap-2 text-gray-700">
                      <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                      <span>Get instant notifications</span>
                    </li>
                    <li className="flex items-center gap-2 text-gray-700">
                      <ArrowUpRight className="h-4 w-4 text-green-500 flex-shrink-0"/>
                      <span>Access detailed information</span>
                    </li>
                  </ul>
                  <button
                      onClick={() => window.location.href = '/subscriptions'}
                      className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded-lg font-medium transition-colors"
                  >
                    Upgrade Now
                  </button>
                </div>
              </div>
          )}
        </div>

        <div className="mt-6">
          <CustomPagination
              currentPage={currentPage}
              totalPageCount={Math.ceil(visibleListings.length / listingsPerPage)}
              onPageChange={setCurrentPage}
              userTier={effectiveTier}
          />
        </div>
      </div>
  );
};

export default MatchedListingsTable;
import React from 'react';
import Navbar from './NavMenu';
import { ArrowRight, Bell, Search, Clock, Star, Shield, Check } from 'lucide-react';

const Home = () => {
  return (
    <>
        < Navbar />
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="relative z-10 py-24 md:py-32">
            <div className="text-center">
              <h1 className="text-4xl md:text-6xl font-bold text-gray-900 mb-6">
                Find Your Dream Property
                <span className="block text-blue-600">Before Anyone Else</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-xl text-gray-600 sm:text-2xl md:mt-5 md:max-w-3xl">
                Get instant alerts for the hottest properties matching your preferences. Never miss out on your perfect home again.
              </p>
              <div className="mt-10 flex justify-center gap-4">
                <button className="px-8 py-3 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors flex items-center">
                  Start Free Trial
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <button className="px-8 py-3 rounded-lg bg-gray-100 hover:bg-gray-200 text-gray-900 font-medium transition-colors">
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-8">
              How Dwellio Works
            </h2>
          </div>
          <div className="grid md:grid-cols-3 gap-8 mt-12">
            <div className="text-center p-6">
              <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Search className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Set Your Preferences</h3>
              <p className="text-gray-600">
                Tell us what you're looking for - location, price range, property type, and more.
              </p>
            </div>
            <div className="text-center p-6">
              <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Bell className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Get Instant Alerts</h3>
              <p className="text-gray-600">
                Receive notifications the moment a property matching your criteria hits the market.
              </p>
            </div>
            <div className="text-center p-6">
              <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                <Clock className="h-8 w-8 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4">Never Miss Out</h3>
              <p className="text-gray-600">
                Be among the first to view and make offers on your dream property.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Benefits Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">
              Why Choose Dwellio?
            </h2>
            <p className="mt-4 text-xl text-gray-600">
              Join thousands of happy home-seekers who found their perfect property
            </p>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                title: "Real-Time Notifications",
                description: "Get instant alerts for new properties that match your criteria",
                icon: <Bell className="h-6 w-6 text-blue-600" />
              },
              {
                title: "Smart Matching",
                description: "Our AI-powered system learns your preferences for better matches",
                icon: <Star className="h-6 w-6 text-blue-600" />
              },
              {
                title: "Market Insights",
                description: "Access detailed property analytics and market trends",
                icon: <Search className="h-6 w-6 text-blue-600" />
              },
              {
                title: "Secure Platform",
                description: "Your data is protected with bank-level security",
                icon: <Shield className="h-6 w-6 text-blue-600" />
              }
            ].map((benefit, index) => (
              <div key={index} className="flex items-start p-6 bg-white rounded-lg shadow-sm">
                <div className="flex-shrink-0">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                    {benefit.icon}
                  </div>
                </div>
                <div className="ml-4">
                  <h3 className="text-xl font-semibold text-gray-900 mb-2">
                    {benefit.title}
                  </h3>
                  <p className="text-gray-600">{benefit.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-blue-600 py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-8">
            Start Your Property Search Today
          </h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Join thousands of satisfied users who found their perfect property using Dwellio's smart alert system.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button className="px-8 py-3 rounded-lg bg-white text-blue-600 font-medium hover:bg-gray-100 transition-colors">
              Start Free Trial
            </button>
            <button className="px-8 py-3 rounded-lg bg-transparent border-2 border-white text-white font-medium hover:bg-blue-700 transition-colors">
              View Pricing
            </button>
          </div>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-white text-lg font-semibold mb-4">Dwellio</h3>
              <p className="text-sm">
                Making property hunting easier and faster with real-time alerts and smart matching.
              </p>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Product</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Features</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Pricing</a></li>
                <li><a href="#" className="hover:text-white transition-colors">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Company</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">About</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Blog</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Contact</a></li>
              </ul>
            </div>
            <div>
              <h4 className="text-white text-lg font-semibold mb-4">Legal</h4>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Privacy</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Terms</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Cookie Policy</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-gray-800 text-center">
            <p className="text-sm">© 2024 Dwellio. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
    </>
    );
};

export default Home;
// components/SubscriptionRequiredModal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Rocket, X } from 'lucide-react';

const SubscriptionRequiredModal = ({ onClose }) => {
    const navigate = useNavigate();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-2xl max-w-lg w-full p-8 relative">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                >
                    <X className="h-6 w-6" />
                </button>

                {/* Icon */}
                <div className="mx-auto w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-6">
                    <Rocket className="h-8 w-8 text-blue-600" />
                </div>

                {/* Content */}
                <div className="text-center mb-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-4">
                        Unlock Property Alerts
                    </h2>
                    <p className="text-gray-600 mb-4">
                        To start receiving personalized property alerts and access the dashboard features, you'll need an active subscription.
                    </p>
                    <p className="text-sm text-gray-500">
                        Choose a plan that suits your needs and start finding your perfect property today.
                    </p>
                </div>

                {/* Buttons */}
                <div className="space-y-3">
                    <button
                        onClick={() => navigate('/subscriptions')}
                        className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                    >
                        View Subscription Plans
                    </button>
                    <button
                        onClick={() => navigate('/')}
                        className="w-full py-3 px-6 rounded-lg border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium transition-colors"
                    >
                        Maybe Later
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionRequiredModal;
// components/SubscriptionProgress.js
import React from 'react';

const SubscriptionProgress = ({ currentStep }) => {
    const steps = [
        { id: 1, name: 'Account', status: currentStep >= 1 ? 'complete' : 'upcoming' },
        { id: 2, name: 'Plan Selection', status: currentStep >= 2 ? 'complete' : 'upcoming' },
        { id: 3, name: 'Payment', status: currentStep >= 3 ? 'complete' : 'upcoming' }
    ];

    return (
        <div className="py-8">
            <div className="max-w-xl mx-auto">
                <div className="relative">
                    {/* Background line */}
                    <div className="absolute top-1/2 left-0 w-full h-0.5 bg-gray-200 -translate-y-1/2" />

                    {/* Progress line */}
                    <div
                        className="absolute top-1/2 left-0 h-0.5 bg-blue-600 -translate-y-1/2 transition-all duration-500"
                        style={{ width: `${Math.max(0, (currentStep - 1) * 50)}%` }}
                    />

                    {/* Steps */}
                    <div className="relative flex justify-between">
                        {steps.map((step, index) => (
                            <div key={step.id} className="flex flex-col items-center">
                                <div className={`
                                    w-8 h-8 rounded-full border-2 flex items-center justify-center
                                    transition-all duration-500
                                    ${step.status === 'complete'
                                    ? 'border-blue-600 bg-blue-600'
                                    : 'border-gray-300 bg-white'}
                                `}>
                                    {step.status === 'complete' ? (
                                        <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                    ) : (
                                        <span className={`
                                            w-2.5 h-2.5 rounded-full
                                            ${currentStep === step.id ? 'bg-blue-600' : 'bg-gray-300'}
                                        `} />
                                    )}
                                </div>
                                <span className={`
                                    mt-2 text-sm font-medium
                                    ${step.status === 'complete' || currentStep === step.id
                                    ? 'text-blue-600'
                                    : 'text-gray-500'}
                                `}>
                                    {step.name}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubscriptionProgress;
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './NavMenu';
import '../custom.css'; // Import your custom CSS
import { Link } from "react-router-dom";

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [emailError, setEmailError] = useState(null);
    const [passwordErrors, setPasswordErrors] = useState([]);
    const [passwordMatchError, setPasswordMatchError] = useState(null);

    const navigate = useNavigate();

    // Regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Function to validate password strength
    const validatePassword = (password) => {
        const requirements = [];
        if (password.length < 8) requirements.push("Password must be at least 8 characters long.");
        if (!/[A-Z]/.test(password)) requirements.push("Password must include at least one uppercase letter.");
        if (!/[0-9]/.test(password)) requirements.push("Password must include at least one number.");
        return requirements;
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Live validation for email
        if (!emailRegex.test(emailValue)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError(null);
        }
    };

    const handlePasswordChange = (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);

        // Live validation for password
        const errors = validatePassword(passwordValue);
        setPasswordErrors(errors);
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmPasswordValue = e.target.value;
        setConfirmPassword(confirmPasswordValue);

        // Live validation for password match
        if (confirmPasswordValue !== password) {
            setPasswordMatchError("Passwords do not match.");
        } else {
            setPasswordMatchError(null);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/Auth/register`, {
                email,
                password,
            });

            // Save the token
            localStorage.setItem('token', response.data.token);

            const pendingSubscription = localStorage.getItem('pendingSubscription');
            if (pendingSubscription) {
                navigate('/subscription-checkout', {
                    state: {
                        tier: pendingSubscription,
                        email: email
                    }
                });
                localStorage.removeItem('pendingSubscription');
            } else {
                navigate('/login', { state: { registered: true } });
            }
        } catch (err) {
            // Handle server errors
            if (err.response && err.response.data) {
                const apiErrors = err.response.data.errors || err.response.data;
                if (Array.isArray(apiErrors)) {
                    const errorMessages = apiErrors.map(error => error.description);
                    setErrors(errorMessages);
                } else if (typeof apiErrors === 'object') {
                    setErrors([apiErrors.description || "Registration failed."]);
                }
            } else {
                setErrors(["An unexpected error occurred. Please try again."]);
            }
        }
    };

    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    {/* Header Section */}
                    <div className="text-center max-w-3xl mx-auto mb-12">
                        <h1 className="text-4xl font-bold text-gray-900 mb-4">
                            Create Your Account
                        </h1>
                        <p className="text-xl text-gray-600">
                            Join us to start receiving personalized property alerts
                        </p>
                    </div>

                    {/* Registration Form */}
                    <div className="max-w-md mx-auto">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8">
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div className="space-y-2">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email address
                                    </label>
                                    <input
                                        id="email"
                                        type="email"
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Enter your email"
                                    />
                                    {emailError && (
                                        <p className="text-sm text-red-600 mt-1">{emailError}</p>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <input
                                        id="password"
                                        type="password"
                                        value={password}
                                        onChange={handlePasswordChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Create a password"
                                    />
                                    {passwordErrors.length > 0 && (
                                        <ul className="mt-1 text-sm text-red-600 list-disc list-inside">
                                            {passwordErrors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                                        Confirm Password
                                    </label>
                                    <input
                                        id="confirmPassword"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                        className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                        placeholder="Confirm your password"
                                    />
                                    {passwordMatchError && (
                                        <p className="text-sm text-red-600 mt-1">{passwordMatchError}</p>
                                    )}
                                </div>

                                {errors.length > 0 && (
                                    <div className="p-4 rounded-lg bg-red-50">
                                        <ul className="list-disc list-inside text-red-600">
                                            {errors.map((error, index) => (
                                                <li key={index}>{error}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}

                                <button
                                    type="submit"
                                    className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    Create Account
                                </button>
                            </form>

                            <div className="mt-6 text-center">
                                <p className="text-gray-600">
                                    Already have an account?{' '}
                                    <Link to="/login" className="text-blue-600 hover:text-blue-700 font-medium">
                                        Sign in
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;

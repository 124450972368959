// Cancel.js
import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './NavMenu';
import { XCircle, ArrowLeft } from 'lucide-react';

const Cancel = () => {
    return (
        <>
            <Navbar />
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
                    <div className="max-w-xl mx-auto">
                        <div className="rounded-2xl border border-gray-200 bg-white shadow-sm p-8 text-center">
                            {/* Cancel Icon */}
                            <div className="mx-auto w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-6">
                                <XCircle className="h-8 w-8 text-gray-600" />
                            </div>

                            <h1 className="text-2xl font-bold text-gray-900 mb-4">
                                Subscription Not Completed
                            </h1>

                            <div className="space-y-4 text-gray-600 mb-8">
                                <p>
                                    The subscription process was cancelled. No charges have been made to your account.
                                </p>
                                <p className="text-sm">
                                    If you experienced any issues or have questions, please don't hesitate to contact our support team.
                                </p>
                            </div>

                            <div className="space-y-4">
                                <Link
                                    to="/subscriptions"
                                    className="inline-flex items-center justify-center w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                                >
                                    <ArrowLeft className="mr-2 h-4 w-4" />
                                    Return to Plans
                                </Link>

                                <div className="text-sm">
                                    <Link
                                        to="/dashboard"
                                        className="text-gray-500 hover:text-gray-700"
                                    >
                                        Back to Dashboard
                                    </Link>
                                </div>

                                <div className="text-sm text-gray-500">
                                    Need help? <a href="mailto:support@dwellio.co.uk" className="text-blue-600 hover:text-blue-700">Contact Support</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Cancel;
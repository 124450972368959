import React from 'react';
import { Pagination } from 'react-bootstrap';

const CustomPagination = ({ currentPage, totalPageCount, onPageChange, userTier }) => {
    const maxVisiblePages = 5; // Maximum number of pages to display before showing ellipses

    const handlePageClick = (page) => {
        // Allow page change for Pro users and only allow Free users to stay on the first page
        if ((userTier === 'Pro' || page === 1) && page !== currentPage) {
            onPageChange(page); // Use the onPageChange prop to handle page change
        }
    };

    const renderPageItems = () => {
        let items = [];

        if (totalPageCount <= maxVisiblePages) {
            // Show all pages if the total count is less than or equal to maxVisiblePages
            for (let i = 1; i <= totalPageCount; i++) {
                items.push(
                    <Pagination.Item
                        key={i}
                        active={i === currentPage}
                        onClick={() => handlePageClick(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }
        } else {
            // Show the first few pages, ellipsis, and the last page
            for (let i = 1; i <= maxVisiblePages; i++) {
                items.push(
                    <Pagination.Item
                        key={i}
                        active={i === currentPage}
                        onClick={() => handlePageClick(i)}
                    >
                        {i}
                    </Pagination.Item>
                );
            }

            items.push(<Pagination.Ellipsis key="ellipsis" disabled />);
            items.push(
                <Pagination.Item
                    key={totalPageCount}
                    active={totalPageCount === currentPage}
                    onClick={() => handlePageClick(totalPageCount)}
                >
                    {totalPageCount}
                </Pagination.Item>
            );
        }

        return items;
    };

    return (
        <Pagination className="justify-content-center">
            <Pagination.First
                onClick={() => handlePageClick(1)}
                disabled={currentPage === 1 || (userTier !== 'Pro' && currentPage === 1)}
            />
            <Pagination.Prev
                onClick={() => handlePageClick(currentPage - 1)}
                disabled={currentPage === 1 || (userTier !== 'Pro' && currentPage === 1)}
            />
            {renderPageItems()}
            <Pagination.Next
                onClick={() => handlePageClick(currentPage + 1)}
                disabled={currentPage === totalPageCount || (userTier !== 'Pro' && currentPage === 1)}
            />
            <Pagination.Last
                onClick={() => handlePageClick(totalPageCount)}
                disabled={currentPage === totalPageCount || (userTier !== 'Pro' && currentPage === 1)}
            />
        </Pagination>
    );
};

export default CustomPagination;

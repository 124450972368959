import Navbar from "./NavMenu";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import { Check, Star, Zap, Shield, X } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Subscriptions = () => {
  const promiseKey = process.env.REACT_APP_STRIPE_PROMISE_KEY;
  const stripePromise = loadStripe(promiseKey);
  const [userSubscription, setUserSubscription] = useState(null);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);
  const navigate = useNavigate();

  const handleSubscribe = async (tier) => {
    const token = localStorage.getItem("token");
    const stripe = await stripePromise;
    const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-checkout-session`,
        {
          tier: tier,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
    );

    const sessionId = response.data;

    // If it's a free subscription, handle success directly
    if (sessionId.startsWith("free_subscription_")) {
      // Redirect to success page or show success message
      window.location.href = `/success`;
      return;
    }

    // For paid subscriptions, redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: sessionId,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const handleSubscriptionClick = (tier) => {
    const token = localStorage.getItem('token');
    if (!token) {
      setSelectedTier(tier);
      setShowAuthModal(true);
      return;
    }
    handleSubscribe(tier);
  };

  const fetchUserSubscription = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/subscription/status`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
      );
      console.log("first", response.data);
      setUserSubscription(response.data);
    } catch (error) {
      console.error("Error fetching subscription:", error);
    }
    console.log("this", userSubscription);
  };

  // Add useEffect to fetch subscription on component mount
  useEffect(() => {
    fetchUserSubscription();
  }, []);

  const features = {
    free: [
      "2 property alerts per day",
      "1 saved search preference",
      "Email notifications",
      "Basic property details",
      "24-hour notification delay",
    ],
    premium: [
      "10 property alerts per day",
      "5 saved search preferences",
      "Email & SMS notifications",
      "Detailed property analytics",
      "1-hour notification delay",
      "Market trend reports",
      "Priority email support",
    ],
    pro: [
      "Unlimited property alerts",
      "Unlimited saved preferences",
      "Real-time notifications",
      "Advanced analytics dashboard",
      "Instant notifications",
      "Market prediction tools",
      "24/7 priority support",
      "Custom alert rules",
      "Price history access",
    ],
  };

  return (
      <>
        <Navbar />
        <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="max-w-7xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
            {/* Header Section */}
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">
                Choose Your Perfect Plan
              </h1>
              <p className="text-xl text-gray-600">
                Get instant access to the hottest properties before anyone else.
                Start with a 14-day free trial of our Pro plan.
              </p>
            </div>

            {/* Pricing Cards */}
            <div className="grid md:grid-cols-3 gap-8 max-w-7xl mx-auto">
              {/* Free Tier */}
              <div
                  className={`rounded-2xl ${
                      userSubscription?.status === "Free"
                          ? "border-2 border-green-500 relative"
                          : "border border-gray-200"
                  } bg-white shadow-sm p-8`}
              >
                {userSubscription?.status === "Free" && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Current Plan
                  </span>
                    </div>
                )}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-gray-900">Free</h3>
                  <h3 className="text-2xl font-bold text-gray-900">Free</h3>
                  <p className="mt-2 text-gray-600">
                    Perfect for getting started
                  </p>
                  <p className="mt-4">
                    <span className="text-4xl font-bold text-gray-900">$0</span>
                    <span className="text-gray-600">/month</span>
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.free.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-3" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Free"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-gray-100 hover:bg-gray-200 text-gray-900"
                    }`}
                    onClick={() => handleSubscriptionClick("Free")}
                    disabled={userSubscription?.status === "Free"}
                >
                  {userSubscription?.status === "Free"
                      ? "Current Plan"
                      : "Get Started"}
                </button>
              </div>

              {/* Premium Tier */}
              <div
                  className={`rounded-2xl ${
                      userSubscription?.status === "Basic"
                          ? "border-2 border-green-500 relative"
                          : "border border-gray-200"
                  } bg-white shadow-sm p-8`}
              >
                {userSubscription?.status === "Basic" && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Current Plan
                  </span>
                    </div>
                )}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-gray-900">Premium</h3>
                  <p className="mt-2 text-gray-600">
                    For active property seekers
                  </p>
                  <p className="mt-4">
                  <span className="text-4xl font-bold text-gray-900">
                    $9.99
                  </span>
                    <span className="text-gray-600">/month</span>
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.premium.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-3" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Basic"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-gray-900 hover:bg-gray-800 text-white"
                    }`}
                    onClick={() => handleSubscriptionClick("basic")}
                    disabled={userSubscription?.status === "Basic"}
                >
                  {userSubscription?.status === "Basic"
                      ? "Current Plan"
                      : "Subscribe Now"}
                </button>
              </div>

              {/* Pro Tier (Highlighted) */}
              <div
                  className={`rounded-2xl ${
                      userSubscription?.status === "Pro"
                          ? "border-2 border-green-500 relative"
                          : "border-2 border-blue-500"
                  } bg-white shadow-lg p-8 relative`}
              >
                {userSubscription?.status === "Pro" ? (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="bg-green-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                    Current Plan
                  </span>
                    </div>
                ) : (
                    <div className="absolute -top-5 left-1/2 transform -translate-x-1/2">
                  <span className="bg-blue-500 text-white px-4 py-2 rounded-full text-sm font-medium flex items-center">
                    <Star className="h-4 w-4 mr-1" />
                    Most Popular
                  </span>
                    </div>
                )}
                <div className="mb-6">
                  <h3 className="text-2xl font-bold text-blue-600">Pro</h3>
                  <p className="mt-2 text-gray-600">
                    For serious property hunters
                  </p>
                  <p className="mt-4">
                  <span className="text-4xl font-bold text-gray-900">
                    $19.99
                  </span>
                    <span className="text-gray-600">/month</span>
                  </p>
                  <p className="mt-2 text-sm text-blue-600">
                    Start with 14 days free trial
                  </p>
                </div>
                <ul className="space-y-4 mb-8">
                  {features.pro.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-blue-500 mr-3" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                  ))}
                </ul>
                <button
                    className={`w-full py-3 px-6 rounded-lg font-medium transition-colors ${
                        userSubscription?.status === "Pro"
                            ? "bg-green-100 text-green-700 cursor-default"
                            : "bg-blue-600 hover:bg-blue-700 text-white"
                    }`}
                    onClick={() => handleSubscriptionClick("pro")}
                    disabled={userSubscription?.status === "Pro"}
                >
                  {userSubscription?.status === "Pro"
                      ? "Current Plan"
                      : "Start Free Trial"}
                </button>
              </div>
            </div>

            {/* Features Section */}
            <div className="mt-24 max-w-4xl mx-auto">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                Why Choose Our Pro Plan?
              </h2>
              <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <Zap className="h-6 w-6 text-blue-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Instant Alerts</h3>
                  <p className="text-gray-600">
                    Be the first to know about new properties with real-time
                    notifications
                  </p>
                </div>
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <Shield className="h-6 w-6 text-blue-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">
                    Advanced Analytics
                  </h3>
                  <p className="text-gray-600">
                    Get detailed insights and market predictions for better
                    decisions
                  </p>
                </div>
                <div className="text-center">
                  <div className="mx-auto w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4">
                    <Star className="h-6 w-6 text-blue-600" />
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Priority Support</h3>
                  <p className="text-gray-600">
                    24/7 dedicated support to help you find your perfect property
                  </p>
                </div>
              </div>
            </div>

            {showAuthModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
                  <div className="bg-white rounded-2xl max-w-md w-full p-8 relative">
                    <button
                        onClick={() => setShowAuthModal(false)}
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    >
                      <X className="h-6 w-6" />
                    </button>

                    <div className="text-center mb-8">
                      <h2 className="text-2xl font-bold text-gray-900 mb-4">
                        Create an Account
                      </h2>
                      <p className="text-gray-600">
                        To start your {selectedTier} subscription, please create an account or sign in.
                      </p>
                    </div>

                    <div className="space-y-4">
                      <button
                          onClick={() => {
                            localStorage.setItem('pendingSubscription', selectedTier);
                            navigate('/register');
                          }}
                          className="w-full py-3 px-6 rounded-lg bg-blue-600 hover:bg-blue-700 text-white font-medium transition-colors"
                      >
                        Create Account
                      </button>

                      <div className="text-center">
                        <span className="text-gray-500">Already have an account?</span>
                      </div>

                      <button
                          onClick={() => {
                            localStorage.setItem('pendingSubscription', selectedTier);
                            navigate('/login');
                          }}
                          className="w-full py-3 px-6 rounded-lg border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium transition-colors"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                </div>
            )}

            {/* FAQ Section */}
            <div className="mt-24 max-w-3xl mx-auto">
              <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
                Frequently Asked Questions
              </h2>
              <div className="space-y-8">
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    Can I cancel anytime?
                  </h3>
                  <p className="text-gray-600">
                    Yes, you can cancel your subscription at any time. If you
                    cancel, you'll continue to have access to your plan until the
                    end of your billing period.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    How does the free trial work?
                  </h3>
                  <p className="text-gray-600">
                    You'll get full access to all Pro features for 14 days. No
                    credit card required to start. Cancel anytime during the trial
                    period.
                  </p>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">
                    What payment methods do you accept?
                  </h3>
                  <p className="text-gray-600">
                    We accept all major credit cards, including Visa, Mastercard,
                    and American Express. We also support PayPal payments.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
  );
};

export default Subscriptions;
import React, { useState, useRef } from 'react';
import { Table, OverlayTrigger, Popover, Button, Spinner, Alert } from 'react-bootstrap';
import { FaCircle } from 'react-icons/fa';
import { Edit3, Pause, Play, Trash2 } from 'lucide-react';

const PreferencesTable = ({ preferences, onEdit, onDelete, onPause }) => {
    return (
      <div className="bg-white rounded-lg shadow-sm overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h3 className="font-semibold text-gray-900">Your Preferences</h3>
          <p className="text-sm text-gray-600 mt-1">Manage your property search criteria</p>
        </div>
        
        <div className="overflow-x-auto">
          <table className="w-full">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Location</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price Range</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Bedrooms</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Listings Matched</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {preferences.map((pref) => (
                <tr key={pref.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">
                    {pref.isActive ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        Paused
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{pref.city}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">£{pref.priceRangeMin} - £{pref.priceRangeMax}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{pref.propertyType}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{pref.minBedrooms} - {pref.maxBedrooms}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{pref.listingsMatched}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <div className="flex space-x-3">
                      <button 
                        onClick={() => onEdit(pref.id)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <Edit3 className="h-4 w-4" />
                      </button>
                      <button 
                        onClick={() => onPause(pref.id, !pref.isActive)}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        {pref.isActive ? <Pause className="h-4 w-4" /> : <Play className="h-4 w-4" />}
                      </button>
                      <button 
                        onClick={() => onDelete(pref.id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };


export default PreferencesTable;
